import { Box, Typography, CardMedia } from '@mui/material';
import { getUser } from '../../store/user/user';
import { useAppDispatch } from '../../store/index';
import { useEffect } from 'react';
import { logoutSP } from 'store/auth/auth';
import { useLocation, useNavigate } from 'react-router-dom';
interface ISPHeader {
    isOpenMenu: boolean;
    setIsOpenMenu: (isOpenMenu: boolean) => void;
}
const SPHeader = ({ setIsOpenMenu, isOpenMenu }: ISPHeader) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const getUserProfile = async () => {
        try {
            dispatch(getUser());
        } catch (error) {}
    };

    const handleLogout = () => {
        dispatch(logoutSP());
    };

    useEffect(() => {
        getUserProfile();
    }, []);
    if (window?.location?.pathname == '/login') return <></>;
    if (location?.pathname === '/') return <></>;
    
    const handleGoBack = () => {
        if (location?.pathname?.includes("sp/calendar") || location?.pathname?.includes("sp/projects_list") || window.location?.pathname == '/sp/jobs' || window.location?.pathname == '/sp/purchase-request' || window.location?.pathname == '/sp/chats') {
            navigate('/');
        } else if(location?.pathname?.includes("chats/detail")) {
            navigate("/sp/chats"); 
        } else if(location?.pathname?.includes("chats/chat") && localStorage.getItem("createChatFromProject") === "yes") {
            navigate(-2); 
        } else {
            navigate(-1); 
        }
    }
    return (
        <Box
            bgcolor="#215493"
            height={44}
            display="flex"
            alignItems="center"
            position="relative"
            justifyContent="center"
            padding="10px 16px"
            sx={{
                '@media only screen and (min-width: 992px)': {
                    display: 'none',
                },
            }}
        >
            <CardMedia
                component="img"
                onClick={handleGoBack}
                sx={{
                    width: '22px',
                    height: '20px',
                    cursor: 'pointer',
                    position: 'absolute',
                    left: '16px',
                }}
                src={process.env.PUBLIC_URL + '/img/back.ico'}
                alt="open_menu_icon"
            />
            <Typography
                fontWeight={500}
                fontFamily="Zen Kaku Gothic Antique"
                color="#fff"
            >
                {location?.pathname?.includes("sp/inspection-records") ? "車両系建設機械始業前点検表" : "サントラスト"}
            </Typography>
            {/* <div/> */}
            {/* <CardMedia
                component="img"
                onClick={handleLogout}
                sx={{
                    width: '22px',
                    height: '20px',
                    cursor: 'pointer',
                }}
                src={process.env.PUBLIC_URL + '/img/logout_ic.svg'}
                alt="open_menu_icon"
            /> */}
        </Box>
    );
};
export default SPHeader;
